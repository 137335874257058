import React from "react";
import { Swipe } from "../home/Swipe";

export function Policy() {
  return (
    <div className="scrollHelperContainer">
      <Swipe />
      <div className="scrollHelper">
        <div className="privPol">
          <h2>Fit-Buddy Privacy Policy</h2>
          <br />
          <h3>Effective Date: 25/5/2023</h3>
          <br />
          <br />
          <h3>Introduction</h3>
          <br />
          <p>
            Welcome to Fit-Buddy! We are committed to protecting the privacy and
            security of our users' information. This privacy policy explains how
            we collect, use, and disclose the information we obtain through your
            use of our app.
          </p>
          <p>
            Our privacy policy applies to all users of Fit-Buddy, including
            those who access and use our app on mobile devices. By using our
            app, you consent to the collection, use, and disclosure of your
            information as described in this privacy policy.
          </p>
          <p>
            We may update this privacy policy from time to time, and any changes
            will be effective upon posting the updated policy on our app. We
            encourage you to review this privacy policy periodically to stay
            informed about how we collect, use, and protect your information.
          </p>
          <p>
            If you have any questions or concerns about our privacy practices or
            this policy, please contact us using the information provided at the
            end of this document.
          </p>
          <br />
          <br />
          <h3>Information We Collect</h3>
          <br />
          <p>
            We collect the following types of information when you use our app:
          </p>
          <ul>
            <li>
              Apple Information: When you log in to our app using your Apple ID,
              we collect your Full Name and Apple ID. This information is used
              for authentication purposes and to personalize your app
              experience.. Additionally, we may collect the "real user
              indicator" provided by Apple to ensure the authenticity of your
              Apple ID.
            </li>
            <li>
              Google Information: When you log in to our app using your Google
              account, we collect your Google Full Name, Google profile picture,
              and Google profile ID. This information is used for authentication
              purposes and to personalize your app experience.
            </li>
            <li>
              Facebook Information: When you log in to our app using your
              Facebook account, we collect your Facebook Full Name, Facebook
              profile picture, and Facebook profile ID. This information is used
              for authentication purposes and to personalize your app
              experience.
            </li>
            <li>
              Account Information: After creating an account, we require users
              to provide their gender and location to use the app.
            </li>
            <li>
              Push Notifications: We may request your permission to send push
              notifications to your device. You have the option to accept or
              decline these notifications, and your decision does not impact
              your ability to use the app.
            </li>
            <li>
              Ad Platforms: Our app utilizes ad platforms like AdSense or AdMob.
              These platforms may collect information to deliver targeted
              advertisements, but we do not have direct control over the data
              they collect. Please refer to the privacy policies of these ad
              platforms for more information on their data collection practices.
            </li>
            <li>
              Friend Feature and Chat Functionality: In addition to the
              information collected, our app also collects certain data related
              to the friend feature and chat functionality. This may include
              user-generated content such as messages, chat history, and
              friend-related information. We collect this information to
              facilitate communication between users, enable the friend feature,
              and enhance the overall user experience.
            </li>
          </ul>
          <br />
          <br />
          <h3>Use of Information </h3>
          <br />
          <p>
            We use the information we collect from users for the following
            purposes:{" "}
          </p>
          <ul>
            <li>
              App Functionality: The information obtained from your Facebook
              account, including your username, profile picture, and profile ID,
              is used for authentication purposes to allow you to log in to our
              app using your Facebook credentials.{" "}
            </li>
            <li>
              Personalization: We utilize the gender and location information
              provided during the account creation process to tailor the app's
              content and features to your preferences. This personalization
              enhances your overall app experience and ensures that the app
              meets your specific needs.{" "}
            </li>
            <li>
              Please note that we do not sell, rent, or disclose your personal
              information to third parties without your consent, except as
              required by law or as necessary for the operation and maintenance
              of the app.{" "}
            </li>
            <li>
              The information we collect, including friend-related data and chat
              content, is used solely within the app for the intended purposes.
              Friend-related information is utilized to display and manage user
              connections, enabling users to connect with their friends. Chat
              content is utilized to facilitate real-time communication between
              users. We may also analyze usage patterns and interactions to
              improve our friend and chat features, personalize user
              experiences, and enhance the functionality of our app. Rest
              assured that the collected information is not used for any
              unauthorized purposes or shared with third parties.{" "}
            </li>
          </ul>
          <br />
          <br />
          <h3>Sharing of Information </h3>
          <br />
          <p>
            We are committed to protecting your privacy and maintaining the
            confidentiality of your information. We do not share your personal
            information with third parties, except as described below:{" "}
          </p>
          <ul>
            <li>
              Advertisements: Our app may display advertisements from
              third-party ad networks, such as AdSense or AdMob. These ad
              networks may collect certain information to deliver targeted
              advertisements to you. However, we do not have direct control over
              the data collected by these ad networks. We encourage you to
              review the privacy policies of these ad networks to understand
              their data collection and usage practices.{" "}
            </li>
            <li>
              Service Providers: We may engage third-party service providers to
              perform certain functions on our behalf, such as hosting, data
              analysis, customer support, or other services that assist in the
              operation of the app. These service providers may have access to
              your personal information only to the extent necessary to perform
              their functions and are obligated to maintain the confidentiality
              and security of your information.{" "}
            </li>
            <li>
              Legal Requirements: We may disclose your information if required
              to do so by law or in response to a valid legal request, such as a
              court order or government regulation.{" "}
            </li>
            <li>
              We understand the importance of user privacy and value the trust
              you place in our app. As such, we do not share user information
              with any third parties outside of the app. While the friend
              feature allows users to connect with each other, the sharing of
              friend-related information is limited to within the app's
              ecosystem. This means that certain information, such as usernames
              or profile pictures, may be visible to other users as part of the
              friend feature. However, we do not disclose or sell user
              information to external parties for marketing or other purposes.{" "}
            </li>
          </ul>
          <br />
          <br />
          <h3>User Rights and Choices </h3>
          <br />
          <p>
            We value your privacy and provide you with certain rights and
            choices regarding your information. Here are the options and
            controls available to you:{" "}
          </p>
          <ul>
            <li>
              Profile Deletion: You have the right to delete your profile and
              associated data from our app. To do so, simply log in to the app
              and navigate to the "Help" page from the drop-down menu. At the
              bottom of the Help page, you will find the option to delete your
              profile. Please note that this action is irreversible and will
              result in the permanent removal of your account and all associated
              data.{" "}
            </li>
            <li>
              Information Access: We do not provide specific mechanisms for
              accessing or modifying your personal information beyond the basic
              profile information associated with your account. If you have any
              concerns or requests regarding your information, please contact us
              using the contact details provided at the end of this privacy
              policy.{" "}
            </li>
            <li>
              You have control over your friend connections in the app. You can
              add or remove friends, accept or refuse friend requests, and
              customize your friend list. However, please note that chat
              messages cannot be individually deleted, and the chat history
              remains within the app. Rest assured that we prioritize user
              privacy and ensure chat content is secure and accessible only to
              the intended recipients.{" "}
            </li>
          </ul>
          <p>
            Please note that while we strive to provide a secure and reliable
            app, no data transmission over the internet or mobile network can be
            guaranteed as 100% secure. As such, we cannot ensure or warrant the
            security of any information you transmit to us, and you do so at
            your own risk.{" "}
          </p>
          <br />
          <br />
          <h3>Data Security </h3>
          <br />
          <p>
            We take the security of your information seriously and have
            implemented appropriate technical and organizational measures to
            protect it from unauthorized access, loss, or misuse. Here are some
            of the security measures we have in place:{" "}
          </p>
          <ul>
            <li>
              Secure Transmission: We use industry-standard protocols to ensure
              the secure transmission of data between your device and our
              servers. This helps protect your information from interception and
              unauthorized access.{" "}
            </li>
            <li>
              Secure Storage: Your information is stored on secure servers with
              restricted access. We employ robust security measures to prevent
              unauthorized access to our systems and regularly update our
              security practices to align with industry standards.{" "}
            </li>
            <li>
              Access Controls: Access to user information is limited to
              authorized personnel who have a legitimate need to access it. We
              enforce strict access controls and require authentication to
              ensure that only authorized individuals can access, modify, or
              delete your information.{" "}
            </li>
            <li>
              Data Minimization: We only collect and retain the information
              necessary for the functioning of our app and the provision of our
              services. We follow data minimization principles to ensure that we
              only store and process the minimum amount of information required.{" "}
            </li>
          </ul>
          <p>
            Despite our best efforts, no method of transmission or storage is
            completely secure. Therefore, while we strive to protect your
            information, we cannot guarantee its absolute security. If you have
            any concerns about the security of your information, please contact
            us using the contact details provided at the end of this privacy
            policy.{" "}
          </p>
          <br />
          <br />
          <h3>Data Retention </h3>
          <br />
          <p></p>
          <ul>
            <li>
              If you choose to delete your profile, all associated personal
              information will be permanently removed from our active databases.
              However, please note that some residual copies of your personal
              information may remain in our backup systems for a limited period
              of time. It's important to know that interactions or content
              you've shared with other users will not be deleted and may still
              be visible to them.
            </li>
            <li>
              We retain user information for as long as it is necessary to
              provide our services and fulfill the purposes outlined in this
              privacy policy. Specifically, the data you provide remains in our
              system as long as your profile is active.{" "}
            </li>
            <li>
              If you choose to delete your profile, all associated personal
              information will be permanently removed from our active databases.
              However, please note that some residual copies of your personal
              information may remain in our backup systems. It's important to
              know that interactions or content you've shared with other users
              will not be deleted and may still be visible to them.
            </li>
            <li>
              We periodically review and assess the data we hold to ensure that
              it is still relevant and necessary for the purposes for which it
              was collected. If certain data is no longer needed and there are
              no legal obligations requiring its retention, we will securely
              delete or anonymize it.{" "}
            </li>
            <li>
              Please note that even if you delete your profile, certain
              information may still be retained.{" "}
            </li>
            <li>
              Regarding chat history, we retain messages in each chat
              conversation to ensure smooth and seamless communication. This
              allows you to refer back to recent messages and maintain context
              within your conversations.{" "}
            </li>
          </ul>
          <br />
          <br />
          <h3>Children's Privacy</h3>
          <br />
          <p>
            Our app is intended for users aged 17 and above. We do not knowingly
            collect personal information from individuals under the age of 17.
            If you are under 17, please refrain from using our app or providing
            any personal information. We prioritize the protection of children's
            privacy and comply with applicable child privacy protection laws.
            Parents and guardians are encouraged to supervise their children's
            online activities. If we discover that we have inadvertently
            collected personal information from a child under 17, we will
            promptly delete it from our records. If you believe your child's
            information has been collected, please contact us at
            fit-buddy@dahab-tech.com .{" "}
          </p>
          <br />
          <br />
          <h3>International Data Transfers</h3>
          <br />
          <p>
            Our app's server is located in Europe, but it is accessible for use
            by individuals worldwide. As a result, user information may be
            transferred internationally to and from our server in accordance
            with applicable data protection laws.{" "}
          </p>
          <p>
            Rest assured that we prioritize the security and protection of user
            data, regardless of the user's location. By using our app, you
            acknowledge and consent to the transfer of your information to our
            server in Europe and the necessary international transfers that may
            occur.{" "}
          </p>
          <p>
            Please note that while our server is located in Europe, the nature
            of the internet means that data may traverse through various
            countries during transmission. However, we maintain our commitment
            to protecting your information and complying with relevant data
            protection laws to the best of our abilities.{" "}
          </p>
          <p>
            If you have any questions or concerns regarding the international
            transfer of your data, please contact us at fit-buddy@dahab-tech.com{" "}
          </p>
          <br />
          <br />
          <h3>Updates to this Privacy Policy </h3>
          <br />
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or legal requirements. Any updates will be
            effective immediately upon posting the revised Privacy Policy on our
            app. We encourage users to review this Privacy Policy periodically
            to stay informed about how we collect, use, and protect their
            information.{" "}
          </p>

          <br />
          <h3>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or the handling of your personal{" "}
          </h3>
          <p>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or the handling of your personal information, please
            contact us at fit-buddy@dahab-tech.com . We value your privacy and
            will respond to any privacy-related inquiries in a timely and
            appropriate manner. Your feedback is important to us as we
            continuously strive to ensure the protection and privacy of our
            users' information.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
