import React from "react";
import "./help.css";
import { web } from "../root/Root";
import { useSelector } from "react-redux";
import homebtn from "../../Pics/help/homebtn.png";
import age from "../../Pics/help/age.png";
import buddies from "../../Pics/help/buddies.png";
import visbleto from "../../Pics/help/visbleto.png";
import push from "../../Pics/public.png";
import push2 from "../../Pics/publicoff.png";
import use from "../../Pics/help/use.png";
import card from "../../Pics/help/card.png";
import repImg from "../../Pics/help/report.png";
import workout from "../../Pics/help/workout.png";
import changePP from "../../Pics/help/changePP.png";
import fav from "../../Pics/help/fav.png";
import user from "../../Pics/help/user.png";
import { selectData, selectLog } from "../home/homeSlice";
import { Swipe } from "../home/Swipe";
import { useNavigate } from "react-router-dom";
import { handleCopyClick } from "../refprog/refProgFuncs";

export function Help({}) {
  const navigate = useNavigate();
  const log = useSelector(selectLog);
  const myinfo = useSelector(selectData);

  return (
    <div className="scrollHelperContainer">
      <Swipe />
      <div className="scrollHelper">
        <div className="helpCont">
          <h1>HELP !</h1>
          <div className="mailTo">
            <p> contact us at </p>
            <a href="mailto:fit-buddy@dahab-tech.com">
              fit-buddy@dahab-tech.com
            </a>
          </div>
          <div style={{ marginBottom: "10px" }} className="eulaandpolicy">
            <p onMouseUp={() => navigate("/eula")}>EULA</p>
            <p onMouseUp={() => navigate("/policy")}>Policy</p>
          </div>
          {log === "logged" ? (
            <div
              onClick={() => handleCopyClick(myinfo ? myinfo.mainid : "", "ID")}
              className="mailTo"
            >
              <p className="activeBlack singleButton2">Copy ID</p>
              <p>{myinfo.mainid}</p>
            </div>
          ) : (
            ""
          )}
          <div className="smallHelp">
            <img style={{ width: "40px" }} src={homebtn} />
            <p> home button</p>
          </div>
          <div className="smallHelp">
            <img className="repButton2" src={repImg} />
            <p>
              report Inappropriate / block user. <br /> blocking a user will
              also hide all your & user's content from them and you
            </p>
          </div>
          <div className="smallHelp">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <img style={{ width: "40px" }} src={push} />
              <img style={{ width: "40px" }} src={push2} />
            </div>
            <p>
              When green, others CAN find you by your favorite sport W/O an
              announced workout plan. When red, they can ONLY find you if you
              announce a workout plan.You can Change this form{" "}
              <strong onMouseUp={() => navigate("/home/profile")}>
                Profile page
              </strong>
            </p>
          </div>
          <div className="smallHelp">
            <img style={{ width: "40px" }} src={changePP} />
            <p>
              {" "}
              Change you Profile picture by pasting an image link, can be
              Facebook pic, Instagram pic, Google drive Pic ect..
            </p>
          </div>
          <div className="smallHelp">
            <img style={{ width: "100px" }} src={fav} />
            <p>
              {" "}
              Set your favorite sport(s) to get notified when a Fit-Buddy is
              interested in your favorites nearby.{" "}
            </p>
          </div>
          <div className="smallHelp">
            <img style={{ width: "120px" }} src={buddies} />
            <p>your buddies, you can also open chat from your buddies</p>
          </div>
          <div className="smallHelp">
            <img src={age} />
            <p> edit age, becarefull you cant change it</p>
          </div>
          <div className="smallHelp">
            <img style={{ width: "120px" }} src={visbleto} />
            <p>
              Choose the gender that you want them to see you, it is not for
              filtering your search
            </p>
          </div>
          <div className="bigHelp">
            <h3>USING THE APP</h3>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <img style={{ width: "120px", margin: "10px" }} src={workout} />
              <p style={{ margin: "auto" }}>
                Click on Workout Button in the home page
              </p>
            </div>
            <p style={{ margin: "10px" }}>
              You will be promoted to a similar page:
            </p>
            <img style={{ width: "80%" }} src={use} />
            <p>1- Choose an activity you want to do.</p>
            <p>2- Set the time that you want to train</p>
            <p>
              3- Optional, you can write any type of Detal or a Prefered
              location of what you planning <br /> example: preferd location ,
              pace, type of excersise, skill or any thing you feel wanting to
              specify{" "}
            </p>
            <p>4- Set the radius of your search</p>
            <p>
              5- Finaly confirm your training details, now you will be visible
              to other buddies with same activity and promoted to see other
              buddies who set the same activity
            </p>
            <h3>Conencting with a buddy</h3>
            <p style={{ marginBottom: "10px" }}>
              6- after confiming you will be promoted to a similar page, you can
              change your goal if you want by clicking on set another goal, or
              clicking on home button
            </p>
            <img style={{ width: "80%" }} src={card} />
            <p style={{ marginTop: "10px" }}>
              7- you can use Time, Location and Detail filters
            </p>
            <p>
              8- When you find a buddy with matching Time and or Location and or
              Detail, u can click on there card to open there profile before
              connecting.{" "}
            </p>
            <img style={{ width: "60%" }} src={user} />
            <p>
              9- After clicking connect the other buddy will be notified that
              you want to conenct with them, you wont be able to chat with them
              untill they accept your connection request.{" "}
            </p>
            <p>
              10- Rhen the other buddy accepts the connection you will be
              notified. you can now chat with that buddy and set ur training
              plan{" "}
            </p>
            <p>11- Remeber be good sports buddy and dont be toxic</p>
          </div>
          {log === "logged" ? (
            <a
              href={`${web}${process.env.REACT_APP_API_URL}/deleteprofile`}
              className="delProfileButton"
            >
              Delete profile
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
