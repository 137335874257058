import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./home.css";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  selectData,
  selectStatus,
  loadHome,
  selectLog,
  setStatus,
  disableRefresh,
  enableRefresh,
  editData,
  setAppVersion,
  playAdInt,
} from "./homeSlice";
import {
  getLocalStorageUsage,
  saveLocation,
  saveMobSubscription,
  updateLocation,
  updateVersion,
} from "../profile/proFuncs";
import { clearInfo as clearReport } from "../root/repChoiceSlice";
import { clearInfo as clearTempProfile } from "../list/tempProfileSlice";
import { clearInfo as clearTempMeetingPage } from "../workoutsManager/tempMeetingPageSlice";
import { clearInfo } from "../user/userSlice";
import { loadReqs } from "../reqs/reqsSlice";
import { loadChatReqs } from "../chatreqs/chatReqsSlice";
import { Profile } from "../profile/Profile";
import { Gender } from "./Gender";
import subToAPP from "../profile/swDev";
import { LoadingHome } from "./LoadingHome";
import { LoadingFeed } from "./LoadingFeed";
import { Swipe } from "./Swipe";
import { NewVersion } from "../root/NewVersion";
import { ForceToUpdate } from "../root/ForceToUpdate";
import { AskPrompet } from "../popUps/AskPrompet";
import { NameFix } from "./NameFix";
import { RootContext } from "../root/RootContextProvider";
import socket from "../../app/io";

const notes = [
  "- Invite multiple friends to your group in 1 action.",
  "- Edit photos and trim videos before posting or sending.",
  "- Capture and instantly share photos or videos.",
  "- Share posts and content easily.",
];

export function Home() {
  const {
    location,
    setLocation,
    canShare,
    setCanShare,
    showShare,
    setShowShare,
    handleShowShare,
    subColor,
    setSubColor,
    didSeeInt,
    setDidSeeInt,
    radius,
    setRadius,
    asktoUpdatePic,
    setAskToUpdatePic,
    textToAsk,
    setTextToAk,
    askTopUpdate,
    setAskTopUpdate,
    askForceUpdate,
    setAskForceUpdate,
    realCords,
    setRealCords,
    selectedGender,
    setSelectedGender,
    version,
    setVersion,
    platform,
    setPlatform,
  } = useContext(RootContext);
  const data = useSelector(selectData);
  const status = useSelector(selectStatus);
  const log = useSelector(selectLog);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const page = useLocation();
  const pagePath = page.pathname;

  useEffect(() => {
    if (status === "failed") {
      navigate("/login");
    }
  }, [status]);

  useEffect(() => {
    if (pagePath === "/home") {
      navigate("/home/profile");
    }
  }, [status]);

  useEffect(() => {
    if (!data) {
      dispatch(loadHome());
    }
  }, [dispatch]);

  useEffect(() => {
    if (log === "logged" && status === "done" && data) {
      //getLocalStorageUsage();
      if (document.visibilityState === "visible") {
        socket.emit("IJoined");
      }
    }
  }, [log, status]);

  useEffect(() => {
    if (log === "logged" && data && !window.calledRequest) {
      //getLocalStorageUsage();
      try {
        window.ReactNativeWebView.postMessage(`logged`);
      } catch (e) {
        console.log("");
      }
      try {
        //dispatch(loadChatReqs(data.mainid));
        //dispatch(loadReqs(data.mainid));
        setSelectedGender(data.gender);
        window.calledRequest = true;
      } catch (e) {
        console.log(e);
      }
    }
    if (
      log === "logged" &&
      didSeeInt === false &&
      data.activesub === null &&
      window.location.pathname !== "/home/profile" &&
      document.visibilityState === "visible"
    ) {
      try {
        setTimeout(() => {
          try {
            window.ReactNativeWebView.postMessage("showOpenAppAd");
          } catch (e) {
            console.log("onPC");
          }

          //window.activateRefresh = 'adVeiw'
          //try {
          //  window.ReactNativeWebView.postMessage(`showFullScreenAd`);
          //} catch (e) {
          //}
        }, 0);
        setDidSeeInt(true);
        return;
      } catch (error) {
        console.log("on browser");
      }
    }
  }, [log]);

  function handleMessageEvent(event) {
    const data = event.data;
    try {
      if (data.length > 0 && data === "goBack") {
        navigate(-1);
      }
    } catch (e) {}
    try {
      if (data.length > 0 && data.startsWith("Navigate:")) {
        const url = data.substring(9);
        navigate(url);
      }
    } catch (e) {}
    try {
      if (data.length > 0 && data.startsWith("Platform:")) {
        const parts = data.split(" ");
        const appPlatform = parts[0].substring(9); // Extract the platform
        const appVersion = parts[1].substring(8); // Extract the version
        setVersion(appVersion);
        dispatch(setAppVersion(appVersion));
        setPlatform(appPlatform);
      }
    } catch (e) {}
    try {
      if (data.length > 0 && data.startsWith("TriggerLocation:")) {
        const rawCords = data.substring(16);
        const cords = JSON.parse(rawCords);
        saveLocation(cords, setLocation, setRealCords);
      }
    } catch (e) {}
    try {
      if (data.length > 0 && data.startsWith("TriggerSubscription:")) {
        const rawSubscription = data.substring(20);
        const subscription = JSON.parse(rawSubscription);
        saveMobSubscription(subscription, setSubColor);
      }
    } catch (e) {}
    if (data === "subed") {
      setSubColor("hsla(72, 100%, 47%, 65%)");
    }
    if (data === "notSubed") {
      setSubColor("rgba(255, 0, 0, 0.274)");
    }
    if (data === "locationDisabled") {
      setLocation(null);
      dispatch(editData({ location: null }));
    }
    if (data === "locationSaved") {
      setLocation("locationSaved");
    }
    if (data === "loadhome") {
      dispatch(loadHome());
    }
    if (data === "refreshApp") {
      dispatch(loadHome());
      dispatch(playAdInt());
    }
  }

  useEffect(() => {
    // Add event listeners when component mounts
    if (typeof window !== "undefined") {
      window.addEventListener("message", handleMessageEvent);
    }
    if (typeof document !== "undefined") {
      document.addEventListener("message", handleMessageEvent);
    }
    // Clean up event listeners when component unmounts
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("message", handleMessageEvent);
      }
      if (typeof document !== "undefined") {
        document.removeEventListener("message", handleMessageEvent);
      }
    };
  }, []);

  useEffect(() => {
    if (status === "done") {
      setLocation("GetReady");
      dispatch(loadChatReqs(data.mainid));
      dispatch(loadReqs(data.mainid));
      try {
        window.ReactNativeWebView.postMessage("callLocationFromExpo");
      } catch (error) {
        updateLocation(setLocation, setRealCords).then(() => {
          subToAPP(setSubColor)
            .then((response) => {
              setSubColor(response);
            })
            .catch(() => {
              setSubColor("rgba(255, 0, 0, 0.274)");
            });
        });
      }
    }
    dispatch(setStatus("final"));
  }, [status]);

  useEffect(() => {
    if (location === "locationSaved") {
      try {
        window.ReactNativeWebView.postMessage("SubscribeNotification");
        setLocation("GoodtoGo");
      } catch {}
    }
    if (location === null) {
      dispatch(editData({ location: null }));
    }
  }, [location]);

  useEffect(() => {
    if (log === "logged") {
      try {
        window.ReactNativeWebView.postMessage("Version");
      } catch (e) {}
    }
  }, [log]);

  useEffect(() => {
    try {
      if (realCords && realCords.includes("latitude")) {
        dispatch(editData({ location: realCords }));
      } else if (realCords === null) {
        dispatch(editData({ location: null }));
      }
    } catch (e) {}
  }, [realCords]);

  useEffect(() => {
    if (version && !window.updatedVersionOnServer) {
      updateVersion(version);
      if (version !== "3.1.0") {
        setAskTopUpdate(true);
        //setAskForceUpdate(true)
      }
      try {
        if (data.picture_url && !window.askedForPic) {
          const picUrl = data.picture_url;
          if (
            picUrl.includes(
              "https://platform-lookaside.fbsbx.com/platform/profilepic"
            )
          ) {
            const img = new Image();
            img.src = picUrl;
            img.onload = () => {
              console.log("Image loaded successfully");
            };
            img.onerror = () => {
              setTextToAk(
                "We've noticed an issue with your profile picture. We recommend uploading a new one; this will help others know that it's really you."
              );
              setAskToUpdatePic(true);
              window.askedForPic = true;
            };
          } else if (picUrl === "https://fitbuddy.fit/logo192.png") {
            setTextToAk(
              "You haven't added a profile picture yet. We recommend uploading one; this will help others know that it's really you."
            );
            setAskToUpdatePic(true);
            window.askedForPic = true;
          }
        }
      } catch (e) {}
    }
  }, [version]);

  const handleYesPicPrompet = () => {
    setAskToUpdatePic(false);
    navigate("/home/profile");
    setTimeout(() => {
      window.postMessage("OpenChangePP");
    }, 200);
  };

  const handleNoPicPrompet = () => {
    setAskToUpdatePic(false);
  };

  if (status === "loading") {
    return <LoadingHome />;
  } else if (log === "logged" && data) {
    if (data.banned === true) {
      return (
        <div className="containerLogin">
          <div className="boxLogin">
            <h1>Banned</h1>
            <p>
              If you think you were wrognfully banned contact
              fit-buddy@dahab-tech.com
            </p>
            <p>
              Stating your ID and the reason u think you might been banned for{" "}
            </p>
            <p>ID: {data.mainid}</p>
          </div>
        </div>
      );
    }
    if (!data.gender) {
      return (
        <Gender
          data={data}
          selectedGender={selectedGender}
          setSelectedGender={setSelectedGender}
        />
      );
    }
    if (data.name === "null null") {
      return <NameFix />;
    }
    return (
      <div>
        <Swipe />
        <Outlet />
        {askTopUpdate ? (
          <NewVersion
            text={"New version Available!"}
            notes={notes}
            setAskTopUpdate={setAskTopUpdate}
          />
        ) : (
          ""
        )}
        {askForceUpdate ? <ForceToUpdate text={"New version Available"} /> : ""}
        {asktoUpdatePic ? (
          <AskPrompet
            text={textToAsk}
            handleYes={handleYesPicPrompet}
            handleNo={handleNoPicPrompet}
          />
        ) : (
          ""
        )}
      </div>
    );
  } else {
    return (
      <div>
        {window.location.pathname === "/home/feed" ? (
          <LoadingFeed />
        ) : (
          <LoadingHome />
        )}
      </div>
    );
  }
}
